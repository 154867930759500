body {
  background-color: rgb(1, 27, 28);
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
}

.text {
  color: white;
}

.navbar {
  animation: fadeInAnimation ease 2s;
  backdrop-filter: blur(50px);
  opacity: 0.8;
}

.card {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  color: white;
}

.card:hover {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  opacity: 0.5;
}

.container {
  min-height: 100vh;
  overflow-y:unset;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.scoll-appear-animation {
  view-timeline: --subjectReveal block;
  animation-timeline: --subjectReveal;

  animation-name: fadeInAnimation;
  animation-fill-mode: both;
  animation-duration: 1s; /* Firefox requires this to apply the animation */
}

.fadeInAnimation {
  opacity: 0;
  animation: fadeIn 2s forwards;
}

#About-btn {
  animation-delay: 0.5s;
}

#Projects-btn {
  animation-delay: 1s;
}

#Contact-btn {
  animation-delay: 1.5s;
}

#Resume-btn {
  animation-delay: 2s;
}

#into-name {
  animation-delay: 3s;
}

#into-description {
  animation-delay: 3.5s;
}

#projects{
  animation-delay: 3s;
}

#contact {
  animation-delay: 3s;
}

#about {
  animation-delay: 3s;
}

.card {
  width: 20rem;
  height: 20rem;
}

@media (max-width: 500px) {
  .btn {
    margin: 1px;
    padding: 5px;
  }

  .nav-item {
    padding: 1px;
  }
}

@media (max-width: 380px) {
  .small-screen-message {
    display: block;
  }
  .App{
    visibility: hidden;
  }
}

@media (min-width: 380px) {
  .small-screen-message {
    display: none;
  }
  .App{
    visibility: visible;
  }
}

@media (max-width: 1020px) {
  #projects-layout {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 1020px) {
  #projects-layout {
    flex-direction: row;
    justify-content: center;
  }
}

